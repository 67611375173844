.password-input-container {
    position: relative;
  }
  
  .password-input {
    padding-right: 40px; /* Adjust this value to leave space for the eye icon */
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust this value to position the eye icon as per your preference */
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .password-toggle i {
    color: #888;
  }
  
  .password-toggle i:hover {
    color: #333;
  }
   .b{
    overflow-y: hidden;
   }